import { styleConfigs } from '@/config/map/layer-styles'
import COLORS from 'COLORS'
import { getTheme } from '@/helpers/theme'
import $store from 'STORE'
import {
  getSizePropByGeomType,
  getStrokeWidthPropByGeomType
} from '@/helpers/map'
import { _set, cloneDeep } from '@/helpers/data-processing'

export const getStyleCondition = ({
  layerId,
  styleAttributes,
  type,
  field,
  subfield, // for opacity: field === color, subfield === opacity
  module,
  subtype
}) => {
  let baseValue

  switch (subfield ?? field) {
    case 'color':
      baseValue = getBaseColorValue({ layerId, type })
      break
    case 'opacity':
      baseValue = getBaseOpacityValue({ layerId, type })
      break
    case 'size':
      baseValue = getBaseSizeValue({ layerId, type, subtype })
      break
    default:
      console.warn(`unknown field: ${field}`)

      return null
  }

  const attribute = styleAttributes[field]?.attribute
  const attributeSteps = styleAttributes[field]?.steps
  const showRelativeValues = styleAttributes[field].showRelativeValues
  const isAbs = styleAttributes[field]?.isAbs
  const attrValue = ['get', attribute]
  const attrExp = isAbs ? ['abs', attrValue] : attrValue
  const exp = ['interpolate', ['exponential', 1], attrExp]
  const attributeSortedSteps = (attributeSteps || [])
    .slice()
    .sort((a, b) => a.step - b.step)

  // const stepField = field === 'size' ? 'value' : null
  const steps = []

  const { min, max } = $store.state[module].fields[layerId][attribute] || {}
  attributeSortedSteps.forEach(s => {
    // посчитать step если проценты
    const step = showRelativeValues
      ? percentagesToValue({ min, max, valueInPercents: s.step })
      : s.step

    if (steps.includes(step)) return
    steps.push(step)
    // exp.push(step, s[subfield ? subfield : stepField || field])
    exp.push(step, s[subfield || field])
  })

  return [
    'case',
    ['==', ['typeof', ['get', attribute]], 'number'],
    exp,
    baseValue
  ]
}

const getBaseStyleConfig = ({ layerId, type }) => {
  const key = styleConfigs[layerId] ? layerId : type

  return styleConfigs[key]?.main
}

const getBaseColorValue = ({ layerId, type }) => {
  const field = 'color'
  const fieldWithPrefix =
    type === 'caption'
      ? `text-${field}`
      : type === 'icon'
      ? `icon-${field}`
      : field

  return (
    getBaseStyleConfig({ layerId, type }).style.paint[fieldWithPrefix] ||
    COLORS[getTheme()].accentPrimary
  )
}

const getBaseOpacityValue = ({ layerId, type }) => {
  return getBaseStyleConfig({ layerId, type }).style.paint.opacity || 1
}

const getBaseSizeValue = ({ layerId, type, subtype = null }) => {
  const sizeType = type === 'icon' ? 'layout' : 'paint'
  // subtype
  const sizeProp =
    subtype === 'stroke'
      ? getStrokeWidthPropByGeomType(type)
      : getSizePropByGeomType(type)

  return getBaseStyleConfig({ layerId, type }).style[sizeType][sizeProp] || 2
}

export const valueToPercentages = ({ value, min, max }) => {
  return Math.round(((value - min) / (max - min)) * 100)
}

export const percentagesToValue = ({ valueInPercents, min, max }) => {
  const value = Math.round(((max - min) / 100) * valueInPercents + min)

  return Math.min(Math.max(value, min), max)
}

export const getStyleConfigWithCalculatedPercentages = ({
  layerStyle,
  id,
  module,
  calculationType = 'toValue' // toValue || toPercentages
}) => {
  const config = cloneDeep(layerStyle)
  const layerType = layerStyle.type

  const items = [
    {
      type: layerType,
      styleAttributes: config.classifiedStyling?.main?.main
    },
    layerStyle?.caption?.enabled && {
      type: 'caption',
      styleAttributes: config.classifiedStyling?.caption?.main
    },
    layerStyle?.icon?.enabled && {
      type: 'icon',
      styleAttributes: config.classifiedStyling?.icon?.main
    },
    layerStyle?.epure?.enabled && {
      type: 'epure',
      styleAttributes: config.classifiedStyling?.epure?.main
    },
    layerStyle?.classifiedStyling?.stroke && {
      type: 'stroke',
      styleAttributes: config.classifiedStyling?.stroke?.main
    },
    layerStyle?.centroid && {
      type: 'centroid',
      styleAttributes: config.classifiedStyling?.centroid?.main
    },
    layerStyle?.centroid && {
      mainType: 'centroid',
      type: 'stroke',
      styleAttributes: config.classifiedStyling?.centroid?.stroke
    }
  ].filter(Boolean)

  items.forEach(({ type, styleAttributes, mainType }) => {
    Object.entries(styleAttributes || {}).forEach(([key, value]) => {
      if (!styleAttributes[key].steps?.length) return

      const styleType = styleAttributes[key]?.styleType

      const doesntNeedPercentagesCalculating =
        (styleType && styleType !== 'attribute') ||
        (calculationType === 'toValue' && !value.showRelativeValues) ||
        (calculationType === 'toPercentages' && value.showRelativeValues)
      // if (
      //   (styleType && styleType !== 'attribute') ||
      //   (calculationType === 'toValue' && !value.showRelativeValues) ||
      //   (calculationType === 'toPercentages' && value.showRelativeValues)
      // ) {
      //   return
      // }

      if (
        !doesntNeedPercentagesCalculating &&
        styleAttributes[key].steps &&
        calculationType === 'toPercentages'
      ) {
        // если надо пересчитать в проценты, то пересчитываем шаги + добавляем showRelativeValues
        const attribute = styleAttributes[key].attribute

        const { min, max } = $store.state[module].fields[id][attribute] || {}

        const calculatedToPercentagesSteps = styleAttributes[key].steps.map(
          step => ({
            ...step,
            step: valueToPercentages({ value: step.step, min, max })
          })
        )
        styleAttributes[key] = {
          ...styleAttributes[key],
          showRelativeValues: true,
          steps: calculatedToPercentagesSteps
        }
      }

      const result = getStyleCondition({
        layerId: id,
        styleAttributes,
        field: key,
        module,
        type: layerStyle.type,
        subtype: type === 'stroke' ? 'stroke' : null
      })

      const isColor = key === 'color'

      const getPath = prop => {
        return {
          [layerType]:
            prop === 'size'
              ? `main.style.paint.${getSizePropByGeomType(layerType)}`
              : `main.style.paint.${layerType}-${prop}`,
          caption: `caption.style.${
            key === 'size' ? 'layout' : 'paint'
          }.text-${prop}`,
          icon: `icon.style.${
            prop === 'size' || prop === 'image' ? 'layout' : 'paint'
          }.icon-${prop}`,
          centroid: `centroid.style.paint.circle-${
            prop === 'size' ? 'radius' : prop
          }`,
          stroke: {
            mainType: {
              centroid: `centroid.style.paint.circle-stroke-${
                prop === 'size' ? 'width' : prop
              }`
            },
            layerType: {
              circle: `main.style.paint.circle-stroke-${
                prop === 'size' ? 'width' : prop
              }`,
              fill: `stroke.style.paint.line-${
                prop === 'size' ? 'width' : prop
              }`
            }
          },
          epure: `epure.style.paint.fill-${prop}`
        }
      }

      const mainPathsConfig = getPath(key)
      const opacityPathsConfig = getPath('opacity')

      let opacityPath = null

      if (type === 'stroke') {
        if (mainType === 'centroid') {
          // обводка центроидов
          opacityPath = opacityPathsConfig.stroke.mainType.centroid
          _set(config, mainPathsConfig.stroke.mainType.centroid, result)
        } else if (layerType === 'circle') {
          // обводка точек
          opacityPath = opacityPathsConfig.stroke.layerType.circle
          _set(config, mainPathsConfig.stroke.layerType.circle, result)
        } else if (layerType === 'fill') {
          // обводка полигонов
          opacityPath = opacityPathsConfig.stroke.layerType.fill
          _set(config, mainPathsConfig.stroke.layerType.fill, result)
        }
      } else {
        opacityPath = opacityPathsConfig[type]
        _set(config, mainPathsConfig[type], result)
      }

      if (isColor) {
        const opacityResult = getStyleCondition({
          layerId: id,
          styleAttributes,
          field: key,
          subfield: 'opacity',
          module,
          type: layerStyle.type
        })
        _set(config, opacityPath, opacityResult)
      }
    })
  })

  return config
}

export const getExpression = options => {
  if (options) {
    const { op, type, field = 'id', args, target } = options

    if (op && field && args) {
      const base = [op]

      switch (op) {
        case 'match':
          base.push(['to-string', ['get', field]])

          for (const key in args) {
            if (key !== 'default') base.push(key)
            base.push(args[key])
          }

          break
        case 'case': {
          if (Array.isArray(target)) {
            target.forEach((val, ndx) => {
              base.push(['==', ['get', field], val], args[ndx])
            })

            base.push(args.at(-1))
          } else {
            base.push(['==', ['get', field], target], ...args)
          }

          break
        }

        case 'interpolate': {
          base.push(Array.isArray(type) ? type : [type], [field])

          for (const key in args) {
            base.push(Number(key), args[key])
          }

          break
        }
      }

      return base
    }
  }
}
